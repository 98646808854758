export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  courtTitle: 'HIGH COURT OF JUSTICE',
  courtTitleSentence: 'High Court of Justice',
  stateTitle: 'DELTA STATE',
  stateTitleSentence: 'Delta State',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.judiciary.dl.gov.ng',
  currentState: 'Delta State',
  currentDivision: '',
  court_bg_path: 'assets/img/court-bg-5.png',
  court_logo_path: 'assets/img/ecms-logos-delta.png',
  court_logo_1: 'assets/img/coat-of-arms-tp.png',
  court_logo_2: 'assets/img/ecms-logos-delta.png',
  paystack_public_key: 'pk_live_507b62c8e035b9a7661a286195827c1094b79095',
  remitta_mode: 'live',
  remitta_api_key: 'RzAwMDA2MzA5OTl8MTIxODAxMTc3NzZ8N2UyMGQ4OWUyYWI4Y2MxZGZiZGY0OWFmYWRmODRjYTQxMzY0OWU3YWFkYmM0ZDQxYjBiMTI0Y2JkMDA2YzM5OWVhYTY1MWY1ZDRhODg0OTM3MWI0OGRhNDQ4YTFhNWQ0MWZhZThhN2VhOTAzOTNlYTk2Nzc3OGM1NjM4YzYxMTM=',
  interswitch_mechant_code: 'VNA',
  interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'LIVE',
  currentCourtInfoEmail: 'info@judiciary.dl.gov.ng',
  // EXTRAS
  showHelpLine: false,
  helpLine: "09137146567, 07073735972",
  showJudiciarySuffix: false,
  showState: false,
  showDivision: true,
  showSupCourtTitle: false,
  supCourtTitle:'The Judiciary,',
  supCourtTitleColor:'red',
  showAffidavitFee: false
};
